<template>
  <div v-if="errnings">
    <v-row
      class="mb-2 p-3 pushxl hidescroll"
      style="flex-wrap: nowrap;"
    >
      <div
        class="userprofile_box white--text listitem p-4 py-4 primary lighten-1 pushxl"
        :style="$root.mobile ? 'width: 55vw;min-width: 55vw;' : 'min-width:270px'"
      >
        <div class="inner pushxl">
          <unicon
            name="pound-circle"
            fill="#FFFFFF"
            height="20"
            class="pushx"
          ></unicon>
          <span>Total Earned</span>
        </div>
        <v-spacer /><span class="font-weight-black text-h5"
          >£{{ totalerrned }}</span
        >
      </div>
      <div
        class="userprofile_box white--text listitem p-4 py-4 teal lighten-1 pushxl"
        :style="$root.mobile ? 'width: 55vw;min-width: 55vw;' : 'min-width:270px'"
      >
        <div class="inner pushxl">
          <unicon
            name="check-circle"
            fill="#FFFFFF"
            height="20"
            class="pushx"
          ></unicon>
          <div>
            <span>Total Earned</span><br /><span style="font-size:0.8rem"
              >Confirmend</span
            >
          </div>
        </div>
        <v-spacer /><span class="font-weight-black text-h5"
          >£{{ totalerrnedconfirmed }}</span
        >
      </div>
      <div
        class="userprofile_box white--text listitem p-4 py-4 amber lighten-1 pushxl"
        :style="$root.mobile ? 'width: 55vw;min-width: 55vw;' : 'min-width:270px'"
      >
        <div class="inner pushxl">
          <unicon
            name="exclamation-circle"
            fill="#FFFFFF"
            height="20"
            class="pushx"
          ></unicon>
          <div>
            <span>Total Earned</span><br /><span style="font-size:0.8rem"
              >Pending</span
            >
          </div>
        </div>
        <v-spacer /><span class="font-weight-black text-h5"
          >£{{ totalerrnedpending }}</span
        >
      </div>
      <div
        class="userprofile_box white--text listitem p-4 py-4 cyan lighten-1 pushxl"
        :style="$root.mobile ? 'width: 55vw;min-width: 55vw;' : 'min-width:270px'"
      >
        <div class="inner pushxl">
          <unicon
            name="credit-card"
            fill="#FFFFFF"
            height="20"
            class="pushx"
          ></unicon>
          <div>
            <span>Total Earned</span><br /><span style="font-size:0.8rem"
              >Online</span
            >
          </div>
        </div>
        <v-spacer /><span class="font-weight-black text-h5"
          >£{{ totalerrendonline }}</span
        >
      </div>
    </v-row>
    <v-list>
      <template v-for="item in errnings">
        <v-list-item class="listitem mb-2" :key="item.id">
          <v-list-item-avatar color="rgba(165, 203, 239, 0.20)">
            <v-img
              v-if="item.owner.avatar != null"
              :src="item.owner.avatar.data.thumbnails[0].url"
            ></v-img>
            <div
              v-else
              style="text-transform: uppercase;width:100%"
              class="primary--text font-size-h4"
            >
              {{ item.owner.first_name.charAt(0) }}
            </div>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title
              v-if="item.payment"
              style="font-weight:500; font-size:1.1rem; display:flex;align-items: center;"
            >
              <div
                :class="item.payment.status == 'pending' ? 'yellow' : 'teal'"
                class="white--text  darken-3 text-center p-1 px-3 rounded pushx"
                style="width:fit-content; font-size:0.7rem"
              >
                {{ item.payment.status }}
              </div>
              <div>
                <span class="text-primary text-weight-bold"
                  >#{{ item.payment.udid }}</span
                >
                Via {{ item.payment.method.name }}
              </div>
            </v-list-item-title>
            <v-list-item-title v-else>
              Free Appointment
            </v-list-item-title>
            <v-list-item-subtitle class="py-1">
              On {{ item.created_on }}
            </v-list-item-subtitle>
            <v-list-item-subtitle>
              For {{ item.type }} appointment with
              <span>
                {{ item.owner.first_name }}
                {{ item.owner.last_name }}</span
              >
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <div
              style="display: flex;flex-direction: column;align-items: flex-end;"
            >
              <span
                v-if="item.payment"
                style="font-size:2.1rem;"
                class="font-weight-bold"
                >£{{ item.payment.amount }}</span
              >
              <span v-else style="font-size:2.1rem;" class="font-weight-bold"
                >Free</span
              >
            </div>
          </v-list-item-action>
        </v-list-item>
      </template>
    </v-list>
  </div>
  <v-row align="center" justify="center" style="height:10vh" v-else>
    <v-progress-circular color="primary" indeterminate />
  </v-row>
</template>
<script>
export default {
  name: "DoctorEarning",
  components: {},
  props: ["uid"],
  data() {
    return {
      doedit: true,
      errnings: null,
    };
  },
  beforeMount() {},
  activated() {
    if (this.errnings == null) {
      this.loadErrnings();
    }
  },
  deactivated() {
    this.errnings = null;
  },
  mounted() {},
  methods: {
    async loadErrnings() {
      const data = await this.$doctor.getItemByID(this.uid, true);
        await this.$doctor.getErrningsByID(data.id,true).then((data) => {
          console.log("errning data", data);
          this.errnings = data;
        });
    },
  },
  computed: {
    totalerrendonline() {
      let total = 0;
      if (this.errnings) {
        this.errnings.forEach((slip) => {
          if (slip.payment && slip.payment.method.code == "POS")
            total += parseInt(slip.payment.amount);
        });
      }
      return total;
    },
    totalerrnedconfirmed() {
      let total = 0;
      if (this.errnings) {
        this.errnings.forEach((slip) => {
          if (slip.payment && slip.payment.status == "confirmed")
            total += parseInt(slip.payment.amount);
        });
      }
      return total;
    },
    totalerrnedpending() {
      let total = 0;
      if (this.errnings) {
        this.errnings.forEach((slip) => {
          if (slip.payment && slip.payment.status == "pending")
            total += parseInt(slip.payment.amount);
        });
      }
      return total;
    },
    totalerrned() {
      let total = 0;
      if (this.errnings) {
        this.errnings.forEach((slip) => {
          if (slip.payment) total += parseInt(slip.payment.amount);
        });
      }
      return total;
    },
  },
};
</script>
